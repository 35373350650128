import { agentModule } from '@/store/modules/agent';
import { NavigationGuard } from 'vue-router';

const disaffiliated: NavigationGuard = (to, from, next) => {
    if (agentModule.agent && agentModule.disaffiliated) {
        next(false);
    } else {
        next();
    }
};

export default disaffiliated;
