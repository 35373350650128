import { NavigationGuard } from 'vue-router';
import { agentModule } from '@/store/modules/agent';

const auth: NavigationGuard = (to, from, next) => {
    if (localStorage.getItem('token')) {
        agentModule
            .getUser()
            .then(() => {
                if (agentModule.agent && !agentModule.agent.banned) {
                    next();
                } else {
                    agentModule.logout();
                }
            })
            .catch(() => {
                next(false);
                agentModule.logout();
            });
    } else {
        next({ name: 'Login', query: { redirectTo: to.path } });
    }
};

export default auth;
