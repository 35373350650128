import { agentModule } from '@/store/modules/agent';
import { NavigationGuard } from 'vue-router';

const emptyRole: NavigationGuard = (to, from, next) => {
    const user = agentModule.agent;
    if (user && user.account && user.account._id) {
        next(false);
    } else {
        next();
    }
};

export default emptyRole;
