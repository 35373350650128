import { NavigationGuard } from 'vue-router';
import { appointmentModule } from '@/store/modules/appointment';

const isVisibleProject: NavigationGuard = (to, from, next) => {
    appointmentModule
        .isVisible(to.params.id)
        .then(() => {
            next();
        })
        .catch(() => {
            next({ name: 'HiddenProject' });
        });
};

export default isVisibleProject;
