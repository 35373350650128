import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import auth from '@/guards/auth';
import login from '@/guards/login';
import multiguard from 'vue-router-multiguard';
import missingInformation from '@/guards/missing-information';
import chooseRole from '@/guards/choose-role';
import isVisibleProject from '@/guards/project-visible';
import disaffiliated from '@/guards/disaffiliated';
import onlyBroker from '@/guards/only-broker';
import emptyRole from '@/guards/empty-role';
import validToken from '@/guards/valid-token';
import getPages from '@/resolvers/get-pages';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        beforeEnter: login,
        component: () => import('../views/Auth/Home.vue')
    },
    {
        path: '/download-pdf/:id',
        name: 'DownloadFile',
        beforeEnter: auth,
        component: () => import('../views/DownloadFile.vue')
    },
    {
        path: '/',
        component: () => import('../layouts/Auth.vue'),
        beforeEnter: login,
        children: [
            {
                path: '/capacitacion',
                name: 'Register',
                component: () => import('../views/Auth/Register.vue')
            },
            {
                path: '/capacitacion/completa-registro/:id',
                name: 'CompleteRegistration',
                beforeEnter: validToken,
                component: () =>
                    import('../views/Auth/CompleteRegistration.vue')
            },
            {
                path: '/send-email',
                name: 'SendEmail',
                component: () =>
                    import('../views/Auth/PasswordReset/SendEmail.vue')
            },
            {
                path: '/reset/:token',
                name: 'Reset',
                component: () => import('../views/Auth/PasswordReset/Reset.vue')
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import('../views/Auth/Login.vue')
            }
        ]
    },
    {
        path: '/',
        component: () => import('../layouts/Store.vue'),
        beforeEnter: multiguard([
            auth,
            chooseRole,
            missingInformation,
            disaffiliated
        ]),
        children: [
            {
                path: '/search',
                name: 'Search',
                component: () => import('../views/ProjectSearch.vue')
            },
            {
                path: '/project/:id',
                name: 'Project',
                component: () => import('../views/Project.vue')
            },
            {
                path: '/discarded',
                name: 'Discarded',
                component: () => import('../views/Discarded.vue')
            },
            {
                path: '/saved-search/:id',
                name: 'SavedSearch',
                component: () => import('../views/ProjectSearch.vue')
            },
            {
                path: '/project-saved-search/:id/:idProject',
                name: 'ProjectSavedSearch',
                component: () => import('../views/Project.vue')
            },
            {
                path: '/discarded-saved-search/:id',
                name: 'DiscardedSavedSearch',
                component: () => import('../views/Discarded.vue')
            }
        ]
    },
    {
        path: '/',
        beforeEnter: auth,
        component: () => import('../layouts/Default.vue'),
        children: [
            {
                path: '/centro-de-ayuda/:url',
                name: 'HelpCenter',
                beforeEnter: getPages,
                component: () => import('../views/HelpCenter.vue')
            },
            {
                path: 'appointment/:state',
                name: 'Appointment',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/Appointment.vue')
            },
            {
                path: 'broker/seguimiento/:state',
                name: 'BrokerAppointment',
                beforeEnter: multiguard([
                    chooseRole,
                    missingInformation,
                    onlyBroker
                ]),
                component: () => import('../views/Appointment.vue')
            },
            {
                path: 'broker/minuta/:state',
                name: 'BrokerMinute',
                beforeEnter: multiguard([
                    chooseRole,
                    missingInformation,
                    onlyBroker
                ]),
                component: () => import('../views/Minute.vue')
            },
            {
                path: 'personal-information',
                name: 'PersonalInformation',
                beforeEnter: chooseRole,
                component: () => import('../views/PersonalInformation.vue')
            },
            {
                path: 'mis-asistentes',
                name: 'Assistant',
                beforeEnter: chooseRole,
                component: () => import('../views/Assistant.vue')
            },
            {
                path: 'minute/:state',
                name: 'Minute',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/Minute.vue')
            },
            {
                path: 'customer',
                name: 'Customer',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/Customer')
            },
            {
                path: 'customer/register',
                name: 'CustomerRegister',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/CustomerRegister.vue')
            },
            {
                path: 'customer/edit/:id',
                name: 'CustomerEdit',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/CustomerRegister.vue')
            },
            {
                path: 'customer/associate/register/:id',
                name: 'CustomerAssociateRegister',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/CustomerRegister.vue')
            },
            {
                path: 'customer/associate/edit/:id',
                name: 'CustomerAssociateEdit',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/CustomerRegister.vue')
            },
            {
                path: 'busquedas-guardadas',
                name: 'SavedSearchFilter',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/SavedSearch.vue')
            },
            {
                path: 'proyectos-listado',
                name: 'Builder',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/Builder.vue')
            },
            {
                path: 'calendario-de-citas',
                name: 'Calendar',
                beforeEnter: multiguard([chooseRole, missingInformation]),
                component: () => import('../views/Calendar.vue')
            },
            {
                path: 'broker/aprobacion-agentes',
                name: 'BrokerRequest',
                beforeEnter: multiguard([
                    chooseRole,
                    missingInformation,
                    onlyBroker
                ]),
                component: () => import('../views/Broker/Request.vue')
            },
            {
                path: 'missing-information',
                name: 'MissingInformation',
                beforeEnter: chooseRole,
                component: () => import('../views/MissingInformation.vue')
            },
            {
                path: 'account-type',
                name: 'AccountType',
                beforeEnter: emptyRole,
                component: () => import('../views/AccountType.vue')
            },
            {
                path: 'company-data',
                name: 'CompanyData',
                beforeEnter: chooseRole,
                component: () => import('../views/CompanyData.vue')
            },
            {
                path: 'change-password',
                name: 'ChangePassword',
                beforeEnter: chooseRole,
                component: () => import('../views/ChangePassword.vue')
            }
        ]
    },
    {
        path: '/email-search/',
        component: () => import('../layouts/CustomerEmail.vue'),
        children: [
            {
                path: ':mailId',
                name: 'Email',
                component: () => import('../views/Email.vue')
            },
            {
                path: 'project/:id/:mailId',
                name: 'EmailProject',
                component: () => import('../views/Project.vue')
            }
        ]
    },
    {
        path: '/',
        component: () => import('../layouts/Mail.vue'),
        children: [
            {
                path: 'proyecto-oculto',
                name: 'HiddenProject',
                component: () => import('../views/Mail/HiddenProject.vue')
            },
            {
                path: 'confirmar-cita/:id',
                name: 'ConfirmAppointment',
                beforeEnter: isVisibleProject,
                component: () => import('../views/Mail/ConfirmAppointment.vue')
            },
            {
                path: 'cancelar-cita/:id',
                name: 'CancelAppointment',
                beforeEnter: isVisibleProject,
                component: () => import('../views/Mail/CancelAppointment.vue')
            },
            {
                path: 'validar-cita/:id',
                name: 'ValidatedAppointment',
                component: () =>
                    import('../views/Mail/ValidatedAppointment.vue')
            },
            {
                path: 'invalidar-cita/:id',
                name: 'InvalidAppointment',
                component: () => import('../views/Mail/InvalidAppointment.vue')
            },
            {
                path: 'minuta/:state/:id',
                name: 'ValidationMinute',
                component: () => import('../views/Mail/ValidationMinute.vue')
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
