import { AppActionTypes } from '@/store/types';
import store from '@/store';
import axios from 'axios';

export function auth() {
    axios.interceptors.response.use(
        response => {
            store.dispatch(AppActionTypes.hideLoadingAction);
            if (
                response &&
                response.config.url &&
                response.data?.status &&
                response.data.status >= 400
            ) {
                if (response.data.status === 401) {
                    store.dispatch('agent/sessionExpired');
                }
                return Promise.reject(response.data);
            }
            return response;
        },
        error => {
            store.dispatch(AppActionTypes.hideLoadingAction);
            if (error?.response?.status === 401) {
                store.dispatch('agent/sessionExpired');
            }
            return Promise.reject(
                error.response ? error.response.data : error.response
            );
        }
    );
}
