import Vue from 'vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/es';
import axios from 'axios';
import App from './App.vue';
import router from '@/router';
import '@/styles.scss';
import { auth } from './interceptors/auth';
import { api } from './interceptors/api';
import store from '@/store';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import customMessages from './config/validate/message';
import { messages } from 'vee-validate/dist/locale/es.json';
import { ValidationRule } from 'vee-validate/dist/types/types';
import { socket } from './socket';
import VueGtm from 'vue-gtm';

Vue.use(VueGtm, {
    id: 'GTM-KB6GTVT',
    enabled: true,
    debug: true,
    vueRouter: router
});

for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...(validation as ValidationRule),
        message: Object.keys(customMessages).includes(rule)
            ? customMessages[rule]
            : messages[rule]
    });
}

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(ElementUI, { locale });
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$socket = socket(process.env.VUE_APP_URL_API);

auth();
api(process.env.VUE_APP_URL_API);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
