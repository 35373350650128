import { agentModule } from '@/store/modules/agent';
import { NavigationGuard } from 'vue-router';

const missingInformation: NavigationGuard = (to, from, next) => {
    if (agentModule.informationIsMissing) {
        next({ name: 'MissingInformation' });
    } else {
        next();
    }
};

export default missingInformation;
