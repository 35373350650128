import { MutationTree } from 'vuex';
import { AppActionTypes, AppState } from './types';
import { Loading } from 'element-ui';

export const mutations: MutationTree<AppState> = {
    [AppActionTypes.showLoading](state) {
        state.loadingInstance = state.ignoreLoading
            ? undefined
            : Loading.service({ fullscreen: true });
    },
    [AppActionTypes.ignoreLoading](state) {
        state.ignoreLoading = !state.ignoreLoading;
    },
    [AppActionTypes.hideLoading](state) {
        if (state.loadingInstance) {
            state.loadingInstance.close();
            state.loadingInstance = undefined;
        }
    }
};
