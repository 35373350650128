import { agentModule } from '@/store/modules/agent';
import { NavigationGuard } from 'vue-router';

const validToken: NavigationGuard = (to, from, next) => {
    agentModule
        .validateRegistrationToken(to.params.id)
        .then(data => {
            agentModule.setTokenData(data);
            next();
        })
        .catch(() => {
            agentModule.setTokenData(null);
            next();
        });
};

export default validToken;
