import { ActionTree } from 'vuex';
import { AppState, AppActionTypes } from './types';

export const actions: ActionTree<AppState, AppState> = {
    [AppActionTypes.showLoadingAction]({ commit }) {
        commit(AppActionTypes.showLoading);
    },
    [AppActionTypes.hideLoadingAction]({ commit }): void {
        commit(AppActionTypes.hideLoading);
    },
    [AppActionTypes.ignoreLoadingAction]({ commit }): void {
        commit(AppActionTypes.ignoreLoadingAction);
    }
};
