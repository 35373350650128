import { wordpressPageModule } from '@/store/modules/wordpress-page';
import { NavigationGuard } from 'vue-router';

const getPages: NavigationGuard = (to, from, next) => {
    wordpressPageModule.getPages()
    .then(() => next())
    .catch(() => next());
};

export default getPages;
