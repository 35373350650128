import { AppActionTypes } from '@/store/types';
import store from '@/store';
import axios from 'axios';

export function api(urlApi: string) {
    axios.interceptors.request.use(config => {
        if (!(config.params && config.params.ignoreLoading)) {
            store.dispatch(AppActionTypes.showLoadingAction);
        }
        const http = new RegExp('^(http|https)://', 'i');
        if (config.url) {
            config.baseURL = http.test(config.url)
                ? ''
                : `${urlApi}/api/`;
        }
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            'token'
        )}`;
        return config;
    });
}
