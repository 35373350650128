import Vue from 'vue';
import Vuex from 'vuex';
import { actions } from './actions';
import { AppState } from './types';
import { mutations } from './mutations';
Vue.use(Vuex);

const initialState: AppState = {
    ignoreLoading: false
};

export default new Vuex.Store({
    state: initialState,
    actions,
    mutations
});
