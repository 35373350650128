import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { Appointment } from '@/interfaces/appoinment';
import { FilterAppointment } from '@/interfaces/filter';
import { Customer } from '@/interfaces/customer';

@Module({ generateMutationSetters: true })
export class AppointmentModule extends VuexModule {
    selectedModel: any | undefined = undefined;
    dialog = false;
    appointment: Appointment | null = null;
    calendarAppointments: Appointment[] = [];

    response = {
        data: [],
        total: 0,
        page: 1
    };

    filter: FilterAppointment = {
        state: 'agendadas',
        page: 1
    };

    counters = {
        canceladas: 0,
        confirmadas: 0,
        expiradas: 0,
        invalidadas: 0,
        solicitadas: 0
    };

    @Action
    async closeOrOpenDialog(value: boolean) {
        this.dialog = value;
    }

    @Action
    async setModel(model: any) {
        this.selectedModel = model;
    }

    @Action
    async updatePaymentCondition(paymentCondition: number) {
        if (this.selectedModel) {
            this.selectedModel = {
                ...this.selectedModel,
                project: {
                    ...this.selectedModel.project,
                    condition_pago: paymentCondition
                }
            };
        }
    }

    @Action
    async updateCommission(comission: number) {
        if (this.selectedModel) {
            this.selectedModel = {
                ...this.selectedModel,
                ...this.selectedModel.project,
                comisionTipologia: comission
            };
        }
    }

    @Action
    async setAppointment(model: Appointment) {
        this.appointment = model;
    }

    @Action
    async registerAppointment(
        body: {
            esClientePrincipal: boolean;
            clienteId: string;
        } & Pick<
            Appointment,
            'projectId' | 'typologyId' | 'fecha' | 'hora'
        >
    ) {
        await Axios.post('citas/registrar', body);
    }

    @Action
    async reagendar(body: { idCita: string; fecha: string; hora: string }) {
        await Axios.post('citas/reagendar', body);
    }

    @Action
    async requestAppointment(body: {
        idCita: string;
        fecha: string;
        hora: string;
        esClientePrincipal: boolean;
        clienteId: string;
    }) {
        await Axios.post('citas/request-appointment', body);
    }

    @Action
    async pre(
        body: {
            esClientePrincipal: boolean;
            clienteId: string;
        } & Pick<Customer, 'phone' | 'anexo' | 'cellphone'> &
            Pick<
                Appointment,
                | 'agenteId'
                | 'projectId'
                | 'typologyId'
                | 'fecha'
                | 'hora'
            >
    ) {
        await Axios.post('citas/pre-cita', body);
    }

    @Action
    async getAppointment(params: Record<string, string | undefined>) {
        const { data } = await Axios.get<{cita: Appointment}>('citas/obtener-datos-de-la-cita', {
            params
        });
        this.selectedModel = {
            ...data.cita,
            _id: data.cita.typologyId,
            nombreTipologia: Array.isArray(data.cita.nombreTipologia)
                ? data.cita.nombreTipologia[0]
                : data.cita.nombreTipologia,
            name: data.cita.nameTipologia,
            comisionTipologia: data.cita.actualConditions
                ? data.cita.actualConditions.typology.comisionTipologia
                : data.cita.comisionTipologia,
            project: {
                _id: data.cita.projectId,
                project_id: data.cita.project_id,
                tradename: data.cita.tradename,
                name: data.cita.name,
                type_project: data.cita.type_project,
                condition_pago: data.cita.actualConditions
                    ? data.cita.actualConditions.project.condition_pago
                    : data.cita.condition_pago
            }
        };
        this.appointment = data.cita;
    }

    @Action
    async setFilter(data: FilterAppointment) {
        this.filter = { ...this.filter, ...data };
    }

    @Action
    async remove(data: { id: string; reason: string }) {
        await Axios.delete(`citas/eliminar/${data.id}`, { data });
    }

    @Action
    async index() {
        const { data } = await Axios.get('citas/filtrar', {
            params: this.filter
        });
        this.response = {
            data: data.citas,
            total: data.totalDocs,
            page: data.page
        };
        this.counters = data.counters;
    }

    @Action
    async calendar() {
        const { data } = await Axios.get('calendario/obtener-citas');
        this.calendarAppointments = data;
    }

    @Action
    async confirmAppointment(body: {
        idCita: string;
        estado: number;
        comentario: string;
    }) {
        await Axios.post('citas/confirmar-cita', body);
    }

    @Action
    async cancelAppointment(body: {
        idCita: string;
        estado: number;
        comentario: string;
    }) {
        await Axios.post('citas/postergar-cita', body);
    }

    @Action
    async validatedAppointment(body: { idCita: string }) {
        await Axios.post('citas/validar-cita-email', body);
    }

    @Action
    async invalidAppointment(body: { idCita: string; comentario: string }) {
        await Axios.post('citas/invalidar-cita-email', body);
    }

    @Action
    isVisible(appointmentId: string) {
        return Axios.get<void>(`citas/visible-project/${appointmentId}`);
    }
}
export const appointmentModule = new AppointmentModule({
    store,
    name: 'appointment'
});
