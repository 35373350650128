import { NavigationGuard } from 'vue-router';

const login: NavigationGuard = (to, from, next) => {
    if (localStorage.getItem('token')) {
        next({ name: 'Customer' });
    } else {
        next();
    }
};

export default login;
