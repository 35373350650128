import { ElLoadingComponent } from 'element-ui/types/loading';

export enum AppActionTypes {
    showLoadingAction = 'SHOW_LOADING_ACTION',
    showLoading = 'SHOW_LOADING',
    hideLoadingAction = 'HIDE_LOADING_ACTION',
    hideLoading = 'HIDE_LOADING',
    ignoreLoadingAction = 'IGNORE_LOADING_ACTION',
    ignoreLoading = 'IGNORE_LOADING'
}

export interface AppState {
    ignoreLoading: boolean;
    loadingInstance?: ElLoadingComponent;
}
