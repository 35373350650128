import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { Politics, User } from '@/interfaces/user';
import { Account } from '@/interfaces/account';
import { Paginate } from '@/interfaces/paginate';
import { Filter } from '@/interfaces/filter';
import { Notification } from 'element-ui';
import router from '@/router';
import { RawLocation } from 'vue-router';
@Module({ generateMutationSetters: true })
export class AgentModule extends VuexModule {
    agent: User | null = null;
    tokenData: Pick<User, 'nombres' | 'email'> | null = null;
    withoutPassword = false;
    childs: Paginate<any> = {
        data: [],
        total: 0
    };

    filter: Filter = {
        page: 1
    };

    get commercialDataIsMissing() {
        if (!this.agent) return true;
        if (this.rol === 'broker') {
            return (
                !this.agent.accountNumber ||
                (this.rol === 'broker' && !this.agent.account.data_billing)
            );
        } else if (this.rol === 'independiente') {
            return (
                !this.agent.ruc ||
                !this.agent.corporateName ||
                !this.agent.accountNumber ||
                this.agent.accounts.length < 1
            );
        } else {
            return (
                (this.agent.memberShipRequest &&
                    this.agent.memberShipRequest.length == 0) ||
                (this.agent.memberShipRequest &&
                    this.agent.memberShipRequest.length > 0 &&
                    this.agent.memberShipRequest[
                        this.agent.memberShipRequest.length - 1
                    ].status != 3)
            );
        }
    }

    get personalDataIsMissing() {
        if (!this.agent) return true;
        return (
            !this.agent.email ||
            !this.agent.avatar ||
            !this.agent.nombres ||
            !this.agent.apellidoMaterno ||
            !this.agent.apellidoPaterno ||
            !this.agent.celular ||
            !this.agent.nroDoc ||
            !this.agent.typoDoc
        );
    }

    get informationIsMissing() {
        if (!this.agent) return true;
        if (this.rol === 'broker') {
            return (
                this.personalDataIsMissing ||
                this.accountTypeIsMissing ||
                !this.agent.account.data_billing
            );
        } else if (this.rol === 'independiente') {
            return (
                this.personalDataIsMissing ||
                this.accountTypeIsMissing ||
                this.commercialDataIsMissing
            );
        } else if (
            (this.rol === 'asociado' &&
                (this.agent.memberShipRequest.length === 0 ||
                    (this.agent.memberShipRequest[0] &&
                        [0, 1, 2, 4].includes(
                            this.agent.memberShipRequest[0].status
                        )))) ||
            this.personalDataIsMissing
        ) {
            return true;
        }
        return false;
    }

    get disaffiliated() {
        return (
            this.agent &&
            this.rol === 'asociado' &&
            this.agent.memberShipRequest[
                this.agent.memberShipRequest.length - 1
            ].status !== 3
        );
    }

    get rol() {
        return this.agent?.account?.rol;
    }

    get accountTypeIsMissing() {
        return !this.agent?.account;
    }

    @Action
    async setFilter(filter: Filter) {
        this.filter = Object.assign(this.filter, filter);
    }

    @Action
    async editAgent(form: Partial<User>) {
        const { data } = await Axios.put<User>('agente/guardar', form);
        this.setAgent(data);
    }

    @Action
    async uploadFile(form: FormData) {
        const { data } = await Axios.post<Pick<User, 'avatar' | 'logo'>>(
            'agente/upload',
            form
        );
        this.setAgent(data);
    }

    @Mutation
    setAgent(body: Partial<User> | null) {
        this.agent =
            this.agent && body ? { ...this.agent, ...body } : (body as User);
    }

    @Action
    async changePassword(body: { password: string; nuevaPassword: string }) {
        await Axios.post('agente/guardar-configuracion', body);
    }

    @Action
    sessionExpired() {
        Notification.error({
            title: 'Sesión Expirada',
            message: 'La sesión actual ha expirado'
        });
        localStorage.removeItem('token');
        router.push("Login");
        this.setAgent(null);
    }

    @Action
    async getUser(): Promise<User | void> {
        const { data } = await Axios.get<User>('usuario/get-datos');
        this.setAgent(data);
    }

    @Action
    async pushPolitics(body: Politics) {
        await Axios.post('agente/push-politics', body);
        if (this.agent) {
            const politics = this.agent.politics
                ? this.agent.politics.concat([{ url: body.url }])
                : [{ url: body.url }];
            this.setAgent({ politics });
        }
    }

    @Action
    async saveAccount(body: Partial<Account>) {
        const { data } = await Axios.post('agente/save-account', body);
        this.setAgent({ account: data });
    }

    @Action
    async temporaryAccount(body: Account) {
        this.setAgent({ account: body });
    }

    @Action
    async getBrokers(search?: string) {
        const { data } = await Axios.get('agente/get-brokers', {
            params: { search, ignoreLoading: true }
        });
        return data;
    }

    @Action
    async validateInformationBroker(mvcs: string) {
        const { data } = await Axios.get(`validate/mvcspj/${mvcs}`);
        return data;
    }

    @Action
    async registrationFirstStep(body: Partial<User>) {
        await Axios.post('usuario/first-step', body);
    }

    @Action
    async registrationSecondStep(body: FormData) {
        await Axios.post('usuario/second-step', body);
    }

    @Action
    async loginFirstStep(body: { email: string }) {
        const { data } = await Axios.post<{ withoutPassword: boolean }>(
            'auth/first-step',
            body
        );
        this.withoutPassword = data.withoutPassword;
    }

    @Action
    async contract(value: boolean) {
        await Axios.post('usuario/signed-contract', {
            accepted: value
        });
        this.setAgent({ signedCookie: value });
    }

    @Action
    async loginSecondStep(body: { email: string; password: string; deviceId: string }) {
        const { data } = await Axios.post<{ accessToken: string }>(
            'auth/second-step',
            body
        );
        localStorage.setItem('token', data.accessToken);
    }

    @Action
    async logout(redirectTo: RawLocation = 'Login') {
        await Axios.get('auth/logout');
        localStorage.removeItem('token');
        router.push(
            typeof redirectTo === 'string' ? { name: redirectTo } : redirectTo
        );
        this.setAgent(null);
    }

    @Action
    async validateMVCS(body: {
        codigoMVCS?: string;
        nroDoc?: string;
    }): Promise<boolean> {
        const { data } = await Axios.get<{ status: boolean }>(
            `validate/mvcs/${body.codigoMVCS}/${body.nroDoc}`
        );
        return data.status;
    }

    @Action
    async sendPasswordReset(email: string) {
        await Axios.post('auth/send-email-password-reset', { email });
    }

    @Action
    async resetPassword(body: { token: string; password: string }) {
        await Axios.post(`auth/reset-password/${body.token}`, {
            password: body.password
        });
    }

    @Action
    async getChilds() {
        const { data } = await Axios.get('agente/get-childs', {
            params: this.filter
        });
        this.childs = data;
    }

    @Action
    async getChildsByName(search?: string) {
        const { data } = await Axios.get('agente/get-childs-by-name', {
            params: { name: search }
        });
        return data;
    }

    @Action
    async addAssistant(email: string) {
        await Axios.post('agente/save-email-asistente', {
            email
        });
        this.setAgent({
            emailAsistents: this.agent?.emailAsistents.concat([email])
        });
    }

    @Action
    async removeAssistant(email: string) {
        await Axios.post('agente/delete-email-asistente', {
            email
        });
        this.setAgent({
            emailAsistents: this.agent?.emailAsistents.filter(
                element => element !== email
            )
        });
    }

    @Action
    async validateRegistrationToken(id: string) {
        const { data } = await Axios.get(
            `usuario/validate-registration-token/${id}`
        );
        return data;
    }

    @Action
    async setTokenData(data: Pick<User, 'nombres' | 'email'> | null) {
        this.tokenData = data;
    }
}
export const agentModule = new AgentModule({
    store,
    name: 'agent'
});
