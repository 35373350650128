import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { WordpressPage } from '@/interfaces/wordpress-page';

@Module({ generateMutationSetters: true })
export class WordpressPageModule extends VuexModule {
    pages: WordpressPage[] = [];
    tree: WordpressPage[] = [];

    nest(items: WordpressPage[], id = 0): WordpressPage[] {
        return items
            .filter(item => item.parent === id)
            .map(item => ({ ...item, children: this.nest(items, item.id) }))
    }

    @Action
    async getPages() {
        if (this.pages.length < 1) {
            const { data } = await Axios.get<WordpressPage[]>('https://nexoagentes.pe/wp-json/wp/v2/pages', {
                params: {
                    _fields: ['id', 'link', 'title', 'parent', 'slug', 'menu_order'],
                    author: '2',
                    orderby: 'menu_order',
                    order: 'asc'
                }
            });
            this.pages = data;
            this.tree = this.nest(data);
        }
    }

}
export const wordpressPageModule = new WordpressPageModule({
    store,
    name: 'wordpressPage'
});
