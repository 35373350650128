import { agentModule } from '@/store/modules/agent';
import { NavigationGuard } from 'vue-router';

const onlyBroker: NavigationGuard = (to, from, next) => {
    if (agentModule.rol === 'broker') {
        next();
    } else {
        next(false);
    }
};

export default onlyBroker;
