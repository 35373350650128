import { ValidationMessageTemplate } from 'vee-validate/dist/types/types';

const messages: Record<string, ValidationMessageTemplate> = {
    email: 'No es un email válido.',
    required: 'Este campo es requerido.',
    min: (campo, { length }) => `Mínimo ${length} caracteres.`,
    max: (campo, { length }) => `Máximo ${length} caracteres.`,
    size: (campo, { size }) => `El campo ${campo} debe ser menor a ${size}kb`
};

export default messages;
