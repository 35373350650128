import { agentModule } from '@/store/modules/agent';
import { NavigationGuard } from 'vue-router';

const chooseRole: NavigationGuard = (to, from, next) => {
    const user = agentModule.agent;
    if (user && !user.account) {
        next({ name: 'AccountType' });
    } else {
        next();
    }
};

export default chooseRole;
